import React from 'react';

import { TopFooterProps } from './model';
import Logo from '../../../components/atoms/Logo';
import Link from '../../../components/atoms/Link';
import { getIconSvg } from '../../../utils/getIconSvg';

const TopFooter = ({
  logo,
  socialmediaTitle,
  contactTitle,
  adresyEmail,
  telefony,
  socialMedia,
  aboutCompany,
}: TopFooterProps) => (
  <div className="top-footer">
    <hr className="style" />
    <div className="top-footer__wrapper">
      {logo ? (
        <div className="top-footer__container top-footer__container-logo">
          <Logo {...{ logo }} customClass="top-footer__logo" />
        </div>
      ) : null}

      {aboutCompany ? (
        <div className="top-footer__container top-footer__container-about">
          <p className="top-footer__card-title">{aboutCompany.title}</p>
          <p className="top-footer__about">{aboutCompany.description}</p>
        </div>
      ) : (
        ''
      )}

      <div className="top-footer__container top-footer__container-contact">
        <p className="top-footer__card-title">{contactTitle}</p>
        {telefony?.map((x) => {
          return (
            <div key={x.id} className="top-footer__box">
              <div className="top-footer__icons">{getIconSvg(x.icon, 'icon')}</div>
              <a href={`tel:${x.numerTelefonu}`} className="top-footer__link">
                {x.numerTelefonu}
              </a>
            </div>
          );
        })}

        {adresyEmail?.map((x) => {
          return (
            <div key={x.id} className="top-footer__box">
              <div className="top-footer__icons">{getIconSvg(x.icon, 'icon')}</div>
              <a
                href={`mailto:${x.email}?subject=Proszę o wycenę projektu&body=Dzień dobry, proszę o wycenę projektu w załączniku.`}
                className="top-footer__link"
              >
                {x.email}
              </a>
            </div>
          );
        })}
      </div>

      <div className="top-footer__container top-footer__container-social">
        <p className="top-footer__card-title">{socialmediaTitle}</p>
        {socialMedia?.map((x) => {
          return (
            <div key={x.id} className="top-footer__box top-footer__logo-wrapper">
              <div className="top-footer__icons">{getIconSvg(x.icon, 'icon')}</div>
              <Link
                to={x.adresWww}
                ariaLabel={x.label}
                className="top-footer__link"
                target="_blank"
              >
                {x.label}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  </div>
);
export default TopFooter;
