import React from 'react';

import { FooterProps } from './model';
import BottomFooter from '../../molecules/BottomFooter';
import TopFooter from '../../molecules/TopFooter';

const Footer = ({
  bottomFooter,
  topFooter
}: FooterProps) => {
  return (
    <footer className="footer">
      <TopFooter {...topFooter} />
      <BottomFooter {...bottomFooter}/>
    </footer>
  );
};

export default Footer;
