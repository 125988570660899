import React, { ReactNode } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Header from '../components/organisms/Header';
import Seo from '../components/atoms/seo/seo';

import { LayoutType } from './model';

import '../styles/style.scss';
import Footer from '../components/organisms/Footer';

const Layout = ({ children }: { children: ReactNode }) => {
  const { header, topFooter, bottomFooter } = useStaticQuery<LayoutType>(graphql`
    {
      header: contentfulHeader {
        logo {
          ...ImageFragment
        }
        brandLink {
          label
          pageLink {
            ... on ContentfulHomePage {
              slug
            }
          }
        }
        navigation {
          label
          pageLink {
            ... on ContentfulKontaktStrona {
              slug
            }
            ... on ContentfulRealizacjeStrona {
              slug
            }
          }
          id
        }
        pageLabel
      }
      topFooter: contentfulFooter {
        logo {
          ...ImageFragment
        }
        backgroundImage {
          ...ImageFragment
        }
        telefony {
          ...TelephoneFragment
        }
        adresyEmail {
          ...EmailFragment
        }
        socialMedia {
          ...SocialMediaFragment
        }
        aboutCompany {
          ...AboutCompanyFragment
        }
        socialmediaTitle
        contactTitle
      }
      bottomFooter: contentfulBottomFooter {
        companyName
      }
    }
  `);

  return (
    <main className="Layout__main">
      <Seo />
      <Header {...header} />
      {children}
      <Footer {...{ bottomFooter, topFooter }} />
    </main>
  );
};

export default Layout;
