import React from 'react';

import NavItem from '../../../components/atoms/NavItem';

import { DesktopNavType } from './model';

const DesktopNav = ({ navItems }: DesktopNavType) => (
  <nav className="desktop-nav">
    {navItems?.map(({ id, label, pageLink: { slug } }) => (
      <NavItem key={id} label={label!} url={slug} />
    ))}
  </nav>
);

export default DesktopNav;
