import React from 'react';

import Image from '../../../components/atoms/Image';
import Link from '../../../components/atoms/Link';

import { LogoProps } from './model';

const Logo = ({ logo, link, toggleMobileMenu, customClass }: LogoProps) => (
  <Link to={link} className={customClass} onClick={toggleMobileMenu}>
    <Image {...logo} objectFit="contain" className="logo" />
  </Link>
);

export default Logo;
