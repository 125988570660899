import React from 'react';
import classNames from 'classnames';

import NavItem from '../../../components/atoms/NavItem';

import { MobileNavTypes } from './model';

const MobileNav = ({ navItems, isOpen, toggleHamburger, customClass }: MobileNavTypes) => {
  const mobileNavClasses = classNames('mobileNav', customClass, {
    'mobileNav--active': isOpen,
  });

  return (
    <div className={mobileNavClasses}>
      <nav className="mobileNav__opened hide--on-desktop">
        {navItems?.map(({ id, label, pageLink: { slug } }) => (
          <NavItem key={id} label={label!} url={slug} onClick={() => toggleHamburger(false)} />
        ))}
      </nav>
    </div>
  );
};

export default MobileNav;
