import React from 'react';

import ExternalLinkIcon from '../components/atoms/IconsSVG/ExternalLinkIcon';
import PhoneIcon from '../components/atoms/IconsSVG/PhoneIcon';
import EmailIcon from '../components/atoms/IconsSVG/EmailIcon';
import { ICONS_SVG } from '../components/atoms/IconsSVG/icons.d';
import FacebookIcon from '../components/atoms/IconsSVG/FacebookIcon';
import XIcon from '../components/atoms/IconsSVG/XIcon';
import InstagramIcon from '../components/atoms/IconsSVG/InstagramIcon';

export const getIconSvg = (variant: string | undefined, className?: string) => {
  if (variant === 'none') return null;

  const iconsMap = {
    [ICONS_SVG.VARIANT.EXTERNAL_LINK]: <ExternalLinkIcon className={className} />,
    [ICONS_SVG.VARIANT.PHONE]: <PhoneIcon className={className} />,
    [ICONS_SVG.VARIANT.EMAIL]: <EmailIcon className={className} />,
    [ICONS_SVG.VARIANT.FACEBOOK]: <FacebookIcon className={className} />,
    [ICONS_SVG.VARIANT.X]: <XIcon className={className} />,
    [ICONS_SVG.VARIANT.INSTAGRAM]: <InstagramIcon className={className} />,
  };

  // @ts-ignore
  return iconsMap[variant];
};
