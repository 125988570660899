export namespace ICONS_SVG {
  export const enum VARIANT {
    EXTERNAL_LINK = 'external-link',
    EMAIL = 'email',
    PHONE = 'phone',
    FACEBOOK = 'facebook',
    X = 'x',
    INSTAGRAM = 'instagram',
  }
}
