import React from 'react';
import type { GatsbyLinkProps } from 'gatsby';
import { Link as GatsbyLink } from 'gatsby';

import { getPageLink } from '../../../utils/getPageLink'

import { LinkProps } from './model.d';

const isExternalLink = (path: string) =>
  path?.startsWith(`http://`) || path?.startsWith(`https://`) || path?.startsWith(`//`);

const Link = <T extends {}>({
  children,
  to,
  activeClassName,
  partiallyActive,
  ariaLabel,
  target,
  id,
  ...props
}: React.PropsWithoutRef<Omit<GatsbyLinkProps<T>, 'to'>> & LinkProps) => {
  const link = getPageLink(to);

  if (target === '_blank') {
    return (
      <a
        {...props}
        href={link}
        aria-label={ariaLabel}
        rel="noopener noreferrer"
        target="_blank"
        id={id}
      >
        {children}
      </a>
    );
  }

  if (isExternalLink(link)) {
    return (
      <a {...props} href={link} aria-label={ariaLabel} id={id}>
        {children}
      </a>
    );
  }

  return (
    <GatsbyLink
      to={link}
      activeClassName={activeClassName}
      partiallyActive={partiallyActive}
      aria-label={ariaLabel}
      id={id}
      {...props}
    >
      {children}
    </GatsbyLink>
  );
};

export default Link;
