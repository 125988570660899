import React from 'react';
import classNames from 'classnames';

import { HamburgerProps } from './model';

const Hamburger = ({ toggleHamburger, customClass, isOpen }: HamburgerProps) => {
  const hamburgerClasses = classNames('hamburger', customClass, { 'hamburger--active': isOpen });

  return (
    <button
      className={hamburgerClasses}
      onClick={toggleHamburger}
      aria-label="button menu"
      type="button"
    >
      <span className="hamburger__bar" />
    </button>
  );
};

export default Hamburger;
