module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Usługi Ogólnobudowlane PRO","short_name":"Usługi Ogólnobudowlane PRO","start_url":"/","background_color":"#fff","theme_color":"#fff","display":"minimal-ui","icon":"/vercel/path0/static/images/icon.png","sizes":"196x196","type":"image/png","purpose":"maskable","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"df5a89a60ef487538c666c015a4a846d"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
