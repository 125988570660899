import React from 'react';

import Link from '../../../components/atoms/Link';

import { NavItemType } from './model';

const NavItem = ({ url, onClick, label }: NavItemType) => (
  <Link className="nav-item" to={url} activeClassName="nav-item--active" onClick={onClick}>
    {label}
  </Link>
);

export default NavItem;
