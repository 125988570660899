import React, { useState } from 'react';

import DesktopNav from '../../../components/molecules/DesktopNav';
import MobileNav from '../../../components/molecules/MobileNav';
import Hamburger from '../../../components/atoms/Hamburger';
import Logo from '../../../components/atoms/Logo';

import { HeaderProps } from './model';

const Header = ({
  logo,
  pageLabel,
  navigation,
  brandLink: {
    pageLink: { slug: link },
  },
}: HeaderProps) => {
  const [isHamburgerMenu, setIsHamburgerMenu] = useState<boolean>(false);

  return (
    <header className="header">
      <div className="header__brand-label">
        {logo && link ? (
          <Logo
            {...{ logo, link }}
            toggleMobileMenu={() => setIsHamburgerMenu(false)}
            customClass="header__logo"
          />
        ) : null}
        {pageLabel ? <h1 className="header__page-title">{pageLabel}</h1> : null}
      </div>
      <DesktopNav navItems={navigation} />
      <Hamburger
        isOpen={isHamburgerMenu}
        toggleHamburger={() => setIsHamburgerMenu(!isHamburgerMenu)}
      />
      <MobileNav
        isOpen={isHamburgerMenu}
        toggleHamburger={setIsHamburgerMenu}
        navItems={navigation}
      />
    </header>
  );
};

export default Header;
