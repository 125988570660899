import React from 'react';

import { BottomFooterProps } from './model';
import Link from '../../../components/atoms/Link';

const BottomFooter = ({ companyName }: BottomFooterProps) => {
  const date = new Date().getFullYear();

  return (
    <div className="bottom-footer">
      <span>
        Copyright © {companyName} {date}
      </span>
      <span>
        Projekt i wykonanie{' '}
        <Link
          to="https://www.kowalskirafal.pl"
          ariaLabel="Rafał Kowalski"
          target="_blank"
          className="bottom-footer__link"
        >
          ЯK Rafał Kowalski
        </Link>{' '}
      </span>
    </div>
  );
};

export default BottomFooter;
