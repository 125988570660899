import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, useStaticQuery } from 'gatsby';

const SEO: React.FC = () => {
  const { site } = useStaticQuery(graphql`
    query SEO {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `);

  const { title, description } = site.siteMetadata;

  return (
    <Helmet title={title} htmlAttributes={{ lang: 'pl' }}>
      <meta name="description" content={description} />
      {title && <meta property="og:url" content={title} />}
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {title && <meta name="twitter:title" content={title} />}
      {description && <meta name="twitter:description" content={description} />}
    </Helmet>
  );
};
export default SEO;
