import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

import { ImageType } from './model';

const Image = ({ image, className, ...imageProps }: ImageType) => {
  if (image?.svg?.content)
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: image.svg.content }} className={className} />;

  if (image?.gatsbyImageData)
    return <GatsbyImage {...imageProps} image={image.gatsbyImageData} className={className} />;

  return null;
};

export default Image;
